<template>
  <v-data-table 
    :headers="headers" 
    :items="items" 
    :search="search"
    :loading="!items"
    :loading-text="$t('loading')"
    sort-by="date" class="elevation-1"
    item-key="id"
    :footer-props="{ showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right' }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{$t('edi')}}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>  
        <div class="text-center">
          <v-btn color="primary" @click="dialog = true">{{$t('add')}}</v-btn>
          <v-dialog v-model="dialog" width="auto">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-text-field v-model="orderConfirmRequestDto.year" outlined clearable dense autocomplete="nope" hide-details="auto" :label="$t('year')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="orderConfirmRequestDto.code" outlined clearable dense autocomplete="nope" hide-details="auto" :label="$t('code')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field v-model="orderConfirmRequestDto.bis" outlined clearable dense autocomplete="nope" hide-details="auto" :label="$t('bis')"> </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete v-model="orderConfirmRequestDto.type" :items="metodoDocTypeCollectionDropdown" :label="$t('documentType')" hide-details="auto" dense clearable autocomplete="nope" outlined ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="modalContent.customerCode">
                  <v-col cols="12" sm="6">
                    <v-card>
                      <v-card-title class="text-h4">{{ modalContent.customerCode }}<br />{{ modalContent.businessName }}</v-card-title>
                      <v-card-subtitle><br />{{ modalContent.orderDate | formatDate }}</v-card-subtitle>
                      <v-card-subtitle>{{ modalContent.address }}<br />{{ !!modalContent.zipCode? `${modalContent.zipCode} - ` : '' }} {{ modalContent.city }}, {{ modalContent.country }} {{ !!modalContent.province? modalContent.province : "" }}</v-card-subtitle>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="openCustomer(modalContent.customerCode)">{{$t('open')}}</v-btn>
                      </v-card-actions>
                    </v-card>
                    <v-card class="mt-3">
                      <v-card-title class="text-h4">
                        {{$t('docType')}}: {{ modalContent.type }}<br />
                        {{$t('code')}}: {{ modalContent.code }}<br />
                        {{$t('bis')}}: {{ modalContent.bis || $t('na') }}<br />
                      </v-card-title>
                      <v-card-subtitle style="text-align: end;">
                        {{$t('total')}}: {{ modalContent.total | tenantCurrency }}<br />
                        {{$t('taxes')}}: {{ modalContent.totalTax | tenantCurrency }}<br />
                        <b>{{$t('totalWithTaxes')}}: {{ modalContent.totalTaxableAmount | tenantCurrency }}</b>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-card class="customList">
                      <v-list flat>
                        <v-list-item-group>
                          <v-list-item v-for="(item, i) in modalContent.rows" :key="i">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.itemDescription"></v-list-item-title>
                              <div style="display: flex;">
                                <v-list-item-title v-text="item.itemCode"></v-list-item-title>
                                <v-list-item-subtitle style="text-align: end;" v-if="!!item.quantity">{{ item.quantity + ' x ' }}{{ item.unitPrice | tenantCurrency }}</v-list-item-subtitle>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>  
                <v-btn @click="closeModal">{{$t('close')}}</v-btn>
                <v-btn class="primary" @click="confirmModal">{{$t('preview')}}</v-btn>
                <v-btn class="primary" @click="doSomething" v-if="!!modalContent.customerCode">{{$t('send')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-toolbar>
    </template>
    <template v-slot:item.documentDate="{ item }">{{ item.documentDate | formatDate }}</template>
    <template v-slot:item.ediDate="{ item }">{{ item.ediDate | formatDate }}</template>
    <template v-slot:item.customerId="{ item }">{{ formattedNameByCode(item.invoiceToIdentifier) }}</template>
    <template v-slot:item.shipToIdentifier="{ item }">{{ formattedNameByCode(item.shipToIdentifier) }}</template>
    <template v-slot:item.customerLocationId="{ item }">{{ customerLocationInfo(item.customerLocationId) }}</template>
    <template v-slot:item.typeId="{ item }">{{ readableOrderType(item.typeId) }}</template>
    <template v-slot:item.statusId="{ item }">{{ readableOrderStatus(item.statusId) }}</template>
  </v-data-table>
</template>

  
  <script>
    import { get, sync, call } from "vuex-pathify";
    import BooleanIcon from '../../components/app/BooleanIcon';
    import NoDataImg from '../../components/NoDataImg.vue';
  
    export default {
      components: {
        NoDataImg,
        BooleanIcon,
      },
      data: () => ({
        expanded: [],
        singleExpand: true,
        widgets: false,
        dialog: false,
        dialogDelete: false,
        dialogView: false,
        search: '',
        editedIndex: -1,
        viewedItem: {},
        defaultItem: {},
        dialog: false,
        modalContent: {},
      }),
      computed: {
        headers(){
          return [
            // { text: 'Year', value: 'year' },
            { text: this.$t('docDate'), value: 'documentDate' },
            { text: this.$t('recDate'), value: 'ediDate' },
            { text: this.$t('type'), value: 'source' },
            { text: this.$t('status'), value: 'status' },
            // { text: 'ID', value: 'orderId' },
            { text: this.$t('id'), value: 'identifier' },
            { text: this.$t('partner'), value: 'customerId' },
            // { text: 'Billing To', value: 'customerId' },
            { text: this.$t('shippingTo'), value: 'shipToIdentifier' },
            // { text: 'Actions', value: 'actions', sortable: false, align:"center" },
          ]
        },
        formTitle() { 
          return this.editedIndex === -1 ? this.$t('newParam', {param:this.$t('document')}) : this.$t('editParam', {param:this.$t('document')}) 
        },
        customerInfo: get("customers/customerInfo"),
        formattedNameByCode: get("customers/formattedNameByCode"),
        customerLocationInfo: get("customerLocations/customerLocationInfo"),
        customers: get("customers/allCustomers"),
        readableOrderStatus: get('edi/readableOrderStatus'),
        readableOrderType: get('edi/readableOrderType'),
        metodoDocTypeCollectionDropdown: get('edi/metodoDocTypeCollectionDropdown'),
        orderConfirmRequestDto: sync('edi/orderConfirmRequestDto'),
        items: get('edi/orderCollection'),
      },
      watch: {},
      created() {
        this.initialize()
      },
      methods: {
        initialize() {
          this.initCustomerCollection()
          // .then(this.customerLocationsGet)
          .then(() => {
            this.apiEdiDataEdiDocumentsGet()
            this.apiEdiDataEdiDataOrderStatusGet()
            this.apiEdiDataEdiDataOrdersTypesGet()
            this.apiMetodoDocPreviewMetodoDocTypesGet()
          })
        },
        handleRow(subject, action) {
          switch (action) {
            case 'update' : 
              this.editItem(subject);
              break;
            case 'delete' :
              return this.deleteItem(subject); 
            default : 
              this.viewItem(subject);
          } 
        },
        closeModal() {
          this.dialog = !this.dialog;
          this.clearOrderConfirmRequestDto();
        },
        confirmModal() {
          this.apiMetodoDocPreviewMetodoDocPreviewPost()
          .then((response) => {
            this.modalContent = response; 
            this.apiEdiDataEdiDocumentsGet(true);
          })
          .catch((e) => { console.error(e) })
        },
        doSomething() {
          this.apiEdiManagementMultiservicePost(this.orderConfirmRequestDto)
          .then((res) => {
            this.closeModal();
          })
        },  
        openCustomer(customerCode = '') {
          window.open(`/customers/${this.customers.find(el => el.code == customerCode.match(/\d+/)[0])?.id ?? 'error' }`, '_blank');
        },
        ...call("utils/*"),
        ...call("edi/*"),
        ...call("customers/*"),
        ...call("customerLocations/*"),
      },
    }
  </script>

  <style>
    .customList {
      max-height: 47vh!important;
      overflow-y: scroll;
    }

  </style>